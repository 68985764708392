import { MiddlewareContext } from './types';
import { useAuthStore } from '@/composables/useAuthStore';
import { useStatus } from '@/composables/useStatus';

export const profileCheck = async ({ next }: MiddlewareContext): Promise<void> => {
  //const { user } = useAuthStore();

  const { setErrorMessage } = useStatus();

  const { checkProfile } = useAuthStore();

  // if after checking the auth, we still aren't authed, return to login

  const goodProfile = await checkProfile();

  if(!goodProfile) {
    setErrorMessage('Please complete required information in your profile!');
    return next({ name: 'profile.details' });
  }

  // otherwise, we must now be authed
  return next();
};
