













import { defineComponent, ref, watch } from '@/plugins/composition';
import { useStatus } from '@/composables/useStatus';
import BaseButton from './base/BaseButton.vue';
import { routeNames } from '@/router/routeNames';

export default defineComponent({
  components: { BaseButton },
  name: 'MessageBar',
  setup() {
    const show = ref(false);

    const { message, isError } = useStatus();

    watch(message, (val) => {
      show.value = !!val;
    });

    return {
      show,
      message,
      isError,
      routeNames,
    };
  },
});
