






import { computed, defineComponent } from '@/plugins/composition';

export default defineComponent({
  name: 'BaseButton',
  props: {
    black: {
      type: Boolean,
      default: false,
    },
    yellow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { attrs }) {
    const isDark = computed(() => {
      if (props.black && !attrs.disabled) {
        return true;
      }

      if (props.yellow && !attrs.disabled) {
        return true;
      }
    });
    const color = computed(() => {
      if (props.black) {
        return 'black';
      }
    });
    const isOutlined = computed(() => !props.black);

    return {
      isDark,
      color,
      isOutlined,
    };
  },
});
