















import {
  cancel,
  cancelText,
  confirm,
  confirmText,
  confirmTitle,
  confirmType,
  confirmed,
  message,
  pending,
} from '@/composables/useConfirmation/useConfirmation';
import { defineComponent } from '@/plugins/composition';
import BaseButton from './base/BaseButton.vue';

export default defineComponent({
  components: { BaseButton },
  name: 'ConfirmDialog',
  setup() {
    return {
      confirmed,
      pending,
      cancel,
      confirm,
      message,
      confirmText,
      cancelText,
      confirmType,
      confirmTitle,
    };
  },
});
