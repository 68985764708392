import { User, UserData } from '@/models/User';
import { computed, reactive, readonly } from '@/plugins/composition';
import { useCreatingBooking } from '@/composables/useCreatingBooking';

interface IAuthState {
  user: User | null;
}

interface IAuthTokenState {
  Token: string | null | never;
}

const initialState: IAuthState = {
  user: null,
};

const initialTokenState: IAuthTokenState = {
  Token: null,
};

const state = reactive({ ...initialState });

const tokenState = reactive({ ...initialTokenState });


const token = computed(() => tokenState.Token as string);



export const useAuthStore = () => {
  const authState = readonly(state);

  // const isAuthed = computed(() => {
  //   const authUser = getSessionStorageAuth();
  //   return (authUser != null);
  // });

  //const isAuthed = computed(() => !!state.user);

  const isAuthed = computed(() => {
    console.log('--------- isAuth --------');

    if(isSessionExpired()) {
      storeLogout();
      return null;
    }

    return !!state.user;
  });

  const user = computed(() => state.user as User);

  const isMember = computed(() => user.value?.data.member);

  const checkAuth = async (): Promise<void> => {
    const localUser = getLocalAuth();
    //const authUser = getSessionStorageAuth();

    if (localUser) {
      storeLogin(new User(localUser?.user?.data));
    } else {
      await retryAuth();
    }
  };

  const checkProfile = async (): Promise<boolean | undefined> => {
    const localUser = getLocalAuth();
    return localUser?.user?.data.customAttributes.isGoodProfile;
  };

  const retryAuth = async (): Promise<void> => {
    try {
      if(isSessionExpired()) {
        return;
      }
      const user = await User.getCurrentUser();
      return storeLogin(user);
    } catch (e) {
      return clearLocalAuth();
    }
  };

  const isSessionExpired = (): boolean => {
    const now = new Date();
    
    const expires = getExpireAuth();
    console.log('Expires: ', expires);
    if (expires !== null) {
      const expirationDate = new Date(expires);
      if (expirationDate < now) {
        return true;
      }
    }
    return false;
  }

  const refreshState = async (): Promise<void> => retryAuth();

  const storeLogin = (user: User): void => {
    state.user = user;
    setLocalAuth(state);
    setSessionStorageAuth(user.data);
    setExpireAuth();
  };

  const setLocalAuth = (state: IAuthState): void => {
    window.localStorage.setItem('auth', JSON.stringify(state));
  };

  const setSessionStorageAuth = (user: UserData): void => {
    sessionStorage.setItem('authUser', JSON.stringify(user));
  }

  const getSessionStorageAuth = (): User | null => {
    console.log('---------- getSessionStorageAuth ----------');
    const storedAuth = sessionStorage.getItem('authUser');
    const now = new Date();
    
    const expires = getExpireAuth();
    console.log(`-------- expires: ${expires} ------ `);
    if (expires !== null) {
      const expirationDate = new Date(expires);
      console.log(`-------- expirationDate: ${expirationDate} - ${now} ------ `);
      if (expirationDate < now) {
        storeLogout();
        return null;
      }
    }
    return storedAuth ? JSON.parse(storedAuth) : null;
  }

  const getLocalAuth = (): IAuthState | null => {
    const storedAuth = window.localStorage.getItem('auth');
    return storedAuth ? JSON.parse(storedAuth) : null;
  };

  const setLocalToken = (token: string): void => {
    tokenState.Token = token;
    window.localStorage.setItem('token', JSON.stringify(tokenState));
  };

  const getLocalToken = (): string | null => {
    const storedToken = window.localStorage.getItem('token');
    // console.log(storedToken, 'storedToken');
    const responseToken = storedToken ? JSON.parse(storedToken) : null;
    if (responseToken) {
      tokenState.Token = responseToken.Token;
    }
    return responseToken;
  };

  const setExpireAuth = () => {
    const currentDate = new Date();
    const expires = new Date();
    expires.setTime(currentDate.getTime() + (120 * 60 * 1000));

    sessionStorage.setItem('sessionExpire', expires.toLocaleString());
  }

  const getExpireAuth = () => {
    return JSON.stringify(sessionStorage.getItem('sessionExpire'));
  }


  const clearLocalAuth = (): void => {
    window.localStorage.clear();
  };

  const storeLogout = (): void => {
    console.log('------- storeLogout --------');
    const { clearSlots, repeatingType } = useCreatingBooking();
    clearSlots();
    repeatingType.value = 'single';
    if(state && state.user) {
      state.user = null;
    }
    
    clearLocalAuth();
    
  };

  return {
    state,
    authState,
    isAuthed,
    storeLogin,
    setLocalAuth,
    getLocalAuth,
    storeLogout,
    user,
    checkAuth,
    refreshState,
    clearLocalAuth,
    isMember,
    token,
    setLocalToken,
    getLocalToken,
    checkProfile
  };
};
